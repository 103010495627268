import React from 'react';
import './App.css';
import { render } from "react-dom";
import { faTwitter, faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import {  } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function App() {
  return (
      <div className="content">
        <h1>Mike Caine</h1>
        <ul className="social">
          <li>
              <FontAwesomeIcon icon={faCamera} />
              &nbsp;
              <a href="https://www.flickr.com/photos/mike_caine/">Flickr</a></li>
            &nbsp;
            &#8226;
            &nbsp;
          <li>
              <FontAwesomeIcon icon={faLinkedin} />
              &nbsp;
              <a href="https://uk.linkedin.com/in/mikeycaine">LinkedIn</a></li>
            &nbsp;
            &#8226;
            &nbsp;
          <li>
              <FontAwesomeIcon icon={faGithub} />
              &nbsp;
              <a href="https://github.com/mcaine">Github</a>
          </li>
        </ul>
      </div>
  );
}

export default App;
